let internetPackageIds = [2040, 2083, 2084];
let googleEventMap = {
    2040: '2gbps Availability Check',
    2083: '4gbps Availability Check',
    2084: '8gbps Availability Check'
};

/*
* These are the IDs to use locally and staging (they should have the same numbers but if you want to change that for
* testing/debug remember to change back to staging IDs for them not freak out when they test it
*/
let singlePromoPackageID = ""
let promoPackageIds = [2040, 4967, 4968, 4206]

if (process.env.REACT_APP_NODE_ENV === 'production' ) {
    internetPackageIds = [2040, 2052, 2064];
    googleEventMap = {
        2040: '2gbps Availability Check',
        2052: '4gbps Availability Check',
        2064: '8gbps Availability Check'
    };

    /*
    * These are the production package ids they gave us for the email gift promo.
    * I'm just going to leave this here in case Marketing comes up with some last minute thing as
    * on Black Friday but this shouldn't be necessary since they didn't give us anything yet
    */
    singlePromoPackageID = ""
    promoPackageIds = [2131, 2132, 2133, 2134, 2135, 2136, 2137, 2146]
}

export {
    internetPackageIds,
    googleEventMap,
    singlePromoPackageID,
    promoPackageIds
}
