import React, { useEffect, useState } from "react";
import "components/OrderPage/StepFour/StepFour.scss";
import TotalOrder from "components/OrderPage/TotalOrder/TotalOrder";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Checkbox from "components/reusable/Checkbox/Checkbox";
import { stepMinus, setStep, stepPlus } from "store/reducers/order/orderSlice";
import Button from "components/reusable/Button/Button";
import ExclamationMark from "assets/svg/ExclamationMark";
import useWindowDimensions from "utils/WindowWidth";
import MobileSteps from "components/OrderPage/MobileSteps/MobileSteps";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";
import { useLocation } from "react-router-dom";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import {
  useSubmitOrderMutation,
  useVerifyInstallationDateTimeMutation,
} from "api/beanfield/OrderPage";
import { toggleModal } from "store/reducers/modal/modalSlice";
import AddressModalContent from "components/OrderPage/YourAddress/AddressModalContent";
import { sendConfirmedInternetPackageEvent, sendGoogleTagEvent } from "services/hooks/GoogleTags";
import { internalUrlReaderWithLangReg } from "utils/wagtail";
import { getCookie, setCookie } from "utils/Cookies";
import { getUTMVariables } from "utils/utm";


const StepFour = () => {
  const dispatch = useDispatch();
  const { order, userInfo, card } = useSelector((state) => state.orderSlice);
  //CMS DATA
  let { data, isLoading, isError, error } = useGetOrderPageData(useLocation);

  const [terms, setTerms] = useState(false);
  const { width } = useWindowDimensions();
  const token = getCookie("token");
  const [
    verifyInstallationDateTime,
    { data: installationVerification, error: installationError },
  ] = useVerifyInstallationDateTimeMutation();

  //Order submit api call
  const [
    submitOrder,
    {
      data: orderSubmitData,
      error: orderSubmitError,
      isLoading: submitOrderLoading,
    },
  ] = useSubmitOrderMutation();

  //Submit order function
  const orderObject = {
    customer: {
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      email: userInfo?.email,
      address_id: userInfo?.address_id,
      unit: String(userInfo?.unit),
      phone: userInfo?.phoneNumber,
    },
    orders: {
      internet: {
        id: order?.internet?.selected,
        cpe_router: order?.internet?.cpe_router,
        wifi_ap: order?.internet?.wifi_ap,
      },
    },
    utms: getUTMVariables(),
    hardware_shortage: null,
    marketing_promotion: null,
    preferred_date: dayjs(order?.installationDate).format("YYYY-MM-DD"), // "2023-03-14"
    preferred_time_period: order?.installationTime2, // Morning, Afternoon etc.
    next_available_date: dayjs(order?.nextInstallationDate).format("YYYY-MM-DD"), // "2023-03-14"
    next_available_time_slot: order?.nextInstallationTime, // 8:00 AM - 11:00 AM, 11:00 AM - 2:00 PM, etc
    preferred_sooner: order?.preferredSooner,
    preferred_sooner_comment: order?.preferredSoonerComment,
    token: token,
  };

  const submitOrderHandler = () => {
    //TV OBJECT
    if (order?.tv?.id) {
      orderObject.orders.iptv = {
        id: order?.tv?.id,
      };
      //THEMEPACKS (channel packs)
      if (order?.tv?.themePacks?.selected) {
        orderObject.orders.iptv.channelPacks = order?.tv?.themePacks?.selected;
      }
      //CHANNELS (a la carte)
      if (order?.tv?.channels?.selected) {
        orderObject.orders.iptv.alacarteChannels =
          order?.tv?.channels?.selected;
      }
      //TOPBOXES
      if (order?.tv?.topBoxes?.id) {
        orderObject.orders.iptv.mediaCentre = {
          id: order?.tv?.topBoxes?.id,
          count: order?.tv?.topBoxes?.selected,
        };
      }
      //STORAGE
      orderObject.orders.iptv.storage = order?.tv?.storage;
    }

    //PHONE OBJECT
    if (order?.homePhone?.id) {
      orderObject.orders.phone = {
        id: order?.homePhone?.id,
      };
    }
    if (card?.cardAdded) {
      orderObject.autopayment = {
        data_key: card?.dataKey,
        is_same_billing: card?.sameBillingAddress,
        po_box: false,
        street_no: null,
        street_name: card?.billingAddress || null,
        postal_code: card?.billingPostal || null,
      };
    }

    submitOrder(orderObject);
    sendConfirmedInternetPackageEvent(order?.internet?.title);
  };

  const handleInternalLinks = (link) => {
    let uri;
    const language = localStorage.getItem("language");
    const region = localStorage.getItem("region");
    if (link.includes("https://internallink.com")) {
      uri =
        "/" + internalUrlReaderWithLangReg(link, `/${language}/${region}/`);
    } else {
      uri = link;
    }
    return uri;
  };

  const transform = (node) => {
    if (node.type === "tag" && node.name === "a") {
      node.attribs.href = handleInternalLinks(node.attribs.href);
    }
  };

  useEffect(() => {
    if (orderSubmitData?.success) {
      setCookie("token", "");
      dispatch(stepPlus());
      //send the google event when there is success
      if (token) {
        sendGoogleTagEvent({ eventName: "Lucky Checkout Step 10" })
      } else {
        sendGoogleTagEvent({ eventName: "Checkout Step 10" })
      }
    }
  }, [orderSubmitData]);

  //Verifying if installation date and time is available
  useEffect(() => {
    verifyInstallationDateTime({
      installationDate: dayjs(order?.installationDate).format("YYYY-MM-DD"), // "2021-09-30"
      installationTime: order?.installationTime2, // Evening, Afternoon etc.
      token: token,
      customer: {
        address_id: userInfo?.address_id,
        unit: userInfo?.unit,
      },
    });
  }, []);

  //Order submit api call ERROR
  const renderError = () => {
    return (
      <div
        style={{ justifyContent: "flex-start" }}
        className="step_four_time_error"
        tabIndex="0"
      >
        <div className="left_content">
          <ExclamationMark />
        </div>
        <div className="right_content">
          <p style={{ flex: 1 }}>{orderSubmitData?.msg}</p>
        </div>
      </div>
    );
  };

  //TARGETING "A" TAGS TO SET TARGET BLANK (Terms and Conditions)
  const aTags = document.getElementsByTagName("a");
  for (let i = 0; i < aTags.length; i++) {
    if (aTags[i].innerHTML === "Terms and Conditions") {
      aTags[i].target = "_blank";
    }
  }

  const handleReviewContact = (event, dispatch) => {
    if (event.key === "Enter" || event.key === " ") {
      dispatch(stepMinus())
    }
  };

  const handleReviewAddress = (event, dispatch) => {
    if (event.key === "Enter" || event.key === " ") {
      dispatch(
        toggleModal({
          children: <AddressModalContent cmsData={data} />,
        })
      );
    }
  };

  const handleReviewInstallationDate = (event, dispatch) => {
    if (event.key === "Enter" || event.key === " ") {
      dispatch(setStep(1));
    }
  };

  const renderContent = () => {
    return (
      <>
        <div className="step_four_container">
          {/* LEFT SIDE */}
          <div className="step_four_sub_container">
            {/* INSTALLATION ERROR */}
            {installationVerification?.error ? (
              <div className="step_four_time_error" tabIndex="0">
                <div className="left_content">
                  <ExclamationMark />
                </div>
                <div style={{ float: "left" }} className="right_content">
                  <p>{data?.step_4_error_text}</p>
                  <span className="Wbold" onClick={() => dispatch(setStep(1))}>
                    {data?.step_4_error_btn_text}
                  </span>
                </div>
              </div>
            ) : null}
            {orderSubmitData?.error ? renderError() : null}
            <div className="step_four_left_wrapper">
              <h2 className="title" tabIndex="0">{data?.step_4_review_header}</h2>
              {width < 768 ? <hr className="top_devider" /> : null}
              <div className="left_sub_wrap">
                <p className="sub_title" tabIndex="0">{data?.step_4_review_body}</p>
                {/* BLOCK 1 */}
                <div className="block_container" tabIndex="0">
                  <div className="block_title">
                    <h4>{data?.step_4_review_contact_h}</h4>
                    <span
                      onClick={() => dispatch(stepMinus())}
                      onKeyDown={(e) => handleReviewContact(e, dispatch)}
                      tabIndex="0"
                    >
                      {data?.step_4_edit_btn_text}
                    </span>
                  </div>
                  <div>
                    <p>
                      {userInfo?.firstName} {userInfo?.lastName}
                    </p>
                    <p>{userInfo?.phoneNumber}</p>
                    <p>{userInfo?.email}</p>
                  </div>
                </div>
                {/* BLOCK 2 */}
                <div className="block_container" tabIndex="0">
                  <div className="block_title">
                    <h4>{data?.step_4_review_address_h}</h4>
                    <span
                      onClick={() =>
                        dispatch(
                          toggleModal({
                            children: <AddressModalContent cmsData={data}/>,
                          })
                        )
                      }
                      onKeyDown={(e) => handleReviewAddress(e, dispatch)}
                      tabIndex="0"
                    >
                      {data?.step_4_edit_btn_text}
                    </span>
                  </div>
                  <p>{userInfo?.unit + "-" + userInfo?.address}</p>
                </div>
                {/* BLOCK 3 */}
                <div className="block_container" tabIndex="0">
                  <div className="block_title">
                    <h4>{data?.step_4_review_install_h}</h4>
                    <span
                      onClick={() => dispatch(setStep(1))}
                      onKeyDown={(e) => handleReviewInstallationDate(e, dispatch)}
                      tabIndex="0"
                    >
                      {data?.step_4_edit_btn_text}
                    </span>
                  </div>
                  <div>
                    <p>
                      {dayjs(order?.installationDate).format("dddd, MMMM DD")}
                    </p>
                    <p>{order?.installationTime}</p>
                  </div>
                </div>
                {/* BLOCK 4 */}
                <div className="block_container" tabIndex="0">
                  <div className="block_title">
                    <h4>{data?.step_4_want_sooner_true_title}</h4>
                  </div>
                  <div>
                    {order?.preferredSooner ? (
                      <p>{data?.step_4_want_sooner_true_text}</p>
                    ) : (
                      <p>{data?.step_4_want_sooner_false_text}</p>
                    )}
                  </div>
                </div>
                {/* BLOCK 5 */}
                {order?.preferredSooner ? (
                  <div className="block_container" tabIndex="0">
                    <div className="block_title">
                      <h4>{data?.step_4_want_sooner_preferred_install_date_title}</h4>
                    </div>
                    <div>
                      <p>{order?.preferredSoonerComment}</p>
                    </div>
                  </div>
                ) : null}
                {/* BLOCK 6 */}
                {/*<div className="block_container">*/}
                {/*  <div className="block_title">*/}
                {/*    <h4>{data?.step_4_review_payment_h}</h4>*/}
                {/*    <span onClick={() => dispatch(stepMinus())}>*/}
                {/*      {data?.step_4_edit_btn_text}*/}
                {/*    </span>*/}
                {/*  </div>*/}
                {/*  <div>*/}
                {/*    {card?.cardType?.length > 0 &&*/}
                {/*    data?.step_4_credit_card_text?.length > 0 ? (*/}
                {/*      <p>*/}
                {/*        {convertTag(*/}
                {/*          data?.step_4_credit_card_text,*/}
                {/*          card?.cardType,*/}
                {/*          "{card}"*/}
                {/*        )}*/}
                {/*      </p>*/}
                {/*    ) : (*/}
                {/*      <p>{data?.step_4_credit_card_not_added_text}</p>*/}
                {/*    )}*/}
                {/*  </div>*/}
                {/*</div>*/}
                <hr className="devider_line"/>
                <div className="step_four_billing_checkbox">
                  <Checkbox
                    title=""
                    onClick={() => {
                      setTerms(!terms);
                    }}
                  />
                  <p tabIndex="0">{convertHtmlToReact(data?.step_4_review_terms, {transform: transform})}</p>
                </div>
              </div>
            </div>
            <div className="step_four_buttons">
              {width > 768 ? (
                <Button
                  disabled={submitOrderLoading}
                  btnStyle={"beanfield-button--hollow Wbold"}
                  preventDefault
                  click={() => dispatch(stepMinus())}
                  style={{width: "10.9375rem"}}
                >
                  {data?.step_4_back_btn_txt}
                </Button>
              ) : null}
              <Button
                disabled={
                  !terms ||
                  installationVerification?.error ||
                  submitOrderLoading
                }
                loading={submitOrderLoading}
                preventDefault
                click={submitOrderHandler}
                style={{ width: width > 768 ? "auto" : "100%" }}
              >
                {data?.step_4_next_btn_txt}
              </Button>
            </div>
          </div>
          {/* RIGHT SIDE */}
          {width > 768 ? (
            <div className="orderPage_right_block_container">
              <TotalOrder />
            </div>
          ) : null}
        </div>
      </>
    );
  };

  return width > 768 ? (
    renderContent()
  ) : (
    <MobileSteps activeStep={3}>{renderContent()}</MobileSteps>
  );
};

export default StepFour;
