import { getQueries } from "api/wagtailAdapter";
import {usePagesApiParser} from "services/hooks/PagesAPIParser";

export const useGetHomePageData = (useLocation) => {
  let {
    page: data,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetHomePageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );
  data = data?.items ? data?.items[0] : data;
  return { data, isLoading, isError, error };
};
